import {Link} from '@reach/router';
import React, {useEffect, useRef, useState} from 'react';


const Navigation = (props) =>
{
  return (
    <>
      <ul>
        <li><Link to={'/'}>Home</Link></li>
        <li><Link to={'/auth'}>Auth Callback</Link></li>
      </ul>
    </>
  );
};

export {Navigation};
