import './App.scss';
import {Router} from '@reach/router';
import {useCallback, useEffect, useState} from 'react';
import {Navigation} from './comps/Navigation';
import {AuthCallback} from './comps/pages/AuthCallback';
import {Home} from './comps/pages/Home';
import {RequestTest} from './comps/pages/RequestTest';


function App() {

  return (
    <div className="App">

      <Navigation />
      <Router>
        <Home path={'/'} />
        <AuthCallback path={'/auth'} />
        <RequestTest path={'/test'} />
      </Router>

      {/*<header className="App-header">*/}

    </div>
  );
}

export default App;
