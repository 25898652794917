import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';


const RequestTest = (props) =>
{
  const onClick = (e) =>
  {

    axios({
      url   : 'api/token',
      method: 'get'
    }).then(res =>
    {
      console.log(res.data);
      debugger;
    }).catch(err => console.log(err));
  };



  return (
    <>
      <h1>ReqTest</h1>
      <button onClick={onClick}>Test Request</button>
    </>
  );
};

export {RequestTest};
