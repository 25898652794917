import axios from 'axios';
import React, {useEffect, useRef, useState} from 'react';


const AuthCallback = (props) =>
{
  const [code, setCode] = useState('');
  const [tokken, setTokken] = useState();
  const [scope, setScope] = useState();
  const [apiDomain, setApiDomain] = useState();

  useEffect(() =>
  {
    console.log('heppa');
    const params = new URLSearchParams(window.location.search);

    const authCode = params.get('code');
    if(authCode)
    {
      console.log('get Auth Code', authCode);
      setCode(authCode);
    }
  }, [code]);


  const onGetTokenClick = () =>
  {
    console.log('getToken', code);

    axios.get('api/token', {
      params: {'code': code}
    }).then(res =>
    {

      setTokken(res.data.access_token);
      setScope(res.data.scope);
      setApiDomain(res.data.api_domain);

      console.log(res.data);
    });

  };


  return (
    <>
      <h1>Auth Callback</h1>
      <pre>
        <span className={'caption'}>AUTH_CODE: </span>{code}<br />
        <span className={'caption'}>access_token: </span>{tokken}<br />
        <span className={'caption'}>scope: </span>{scope}<br />
        <span className={'caption'}>api_domain: </span>{apiDomain}<br />
        <br />
        <button type={'button'} onClick={onGetTokenClick}>Get Token</button>
      </pre>
    </>
  );
};

export {AuthCallback};
