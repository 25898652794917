import React, {useEffect, useRef, useState} from 'react';


const Home = (props) =>
{

  const [response, setResponse] = useState();
  const [postMsg, setPostMsg] = useState('');
  const [postResponse, setPostResponse] = useState();

  useEffect(() =>
  {
    console.log('resp');

    const apiCall = async() =>
    {
      const result = await fetch('/api/hello');
      const body = await result.json();
      return body.msg;
    };

    apiCall().then(res => setResponse(res));

  }, []);

  const onSubmitCall = (e) =>
  {
    e.preventDefault();
    console.log('post test');

    const apiPost = async() =>
    {
      const response = await fetch('/api/post', {
        method : 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body   : JSON.stringify({post: postMsg})
      });

      const body = await response.text();

      debugger;
      return body;

    };

    apiPost().then(res =>
    {
      console.log('res', res);

      setPostResponse(res);
    });


  };

  return (
    <>
      <h1>Home</h1>
      <h1>{response}</h1>

      <form onSubmit={onSubmitCall}>

        <input type={'text'} value={postMsg} onChange={e => setPostMsg(e.target.value)} />
        <button type={'submit'}>Submit</button>
      </form>

      <h4>Post: {postResponse}</h4>

    </>
  );
};

export {Home};
